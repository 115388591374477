import React, { useEffect, useState } from 'react';
import ListTable from '../../../../common/Table';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../store/action';
import Modal from '../../../../common/modal';
import Confirmation from '../../../../common/modal/Confirmation';
import EditQuestion from './components/EditQuestion';
import AddQuestion from './components/AddQuestion';
import { useHistory } from "react-router-dom";
import { Exam } from '../../../common/model/Exam'
import { useParams } from "react-router";
import { Question } from '../../../common/model/Question';
import ExamPublishRule from '../../../common/component/ExamPublishRule';
import QuestionSummary from '../../../common/component/QuestionSummary';

const Questions = () => {
    const questionList = useSelector((state: any) => {
        return state.adminReducer.questions;
    });
    const examList: Exam[] = useSelector((state: any) => {
        return state.adminReducer.exams;
    });

    const history = useHistory();
    const { examId } = useParams<{ examId: string }>();
    const user = useSelector((state: any) => state.authReducer.user);

    const dispatch = useDispatch();
    const [questionType, setQuestionType] = useState("Reading")
    const [questions, setQuestions] = useState<Question[]>([]);
    const [showModal, setShowModal] = useState(false);
    const [editUserObj, setEditUserObj] = useState({ id: -1 });
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [modalMode, setModalMode] = useState('add');
    const [currentExam, setCurrentExam] = useState<Exam>({})

    useEffect(() => {
        dispatch(actions.getQuestionsAdminSuccess([]));
        user.uid && loadQuestions();
        // user._id && loadListening();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if (examId) {
            const exam: any = examList.find((exam) => exam._id === examId)
            exam ? setCurrentExam(exam) : history.push('/admin/exams')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [examList])

    useEffect(() => {
        const qList = questionList.filter((q: Question) => q.type === questionType)
        setQuestions(qList);
    }, [questionList, questionType])

    const loadQuestions = async () => {
        await dispatch(actions.getQuestionsFromExamAdmin(examId));
    }

    const deleteConfirmation = async () => {
        setShowConfirmation(false);
    }

    const addAction = () => {
        if (questions.length < 20) {
            setModalMode('add');
            setShowModal(true);
        } else {
            dispatch(
                actions.setToasterState({
                    appear: true,
                    title: 'error',
                    name: 'Change Question Type',
                    message: 'Switch to Reading or Listening Mode',
                })
            );
        }
    }

    const viewQuestionSummary = () => {
        setModalMode('QuestionSummary');
        setShowModal(true);
    }

    const editQuestion = async (payload: Question, imagePayload: any) => {
        await dispatch(actions.editQuestionAdmin(payload._id, payload, imagePayload));
    }

    const editAction = (obj: any) => {
        setModalMode('edit');
        setEditUserObj(obj);
        setShowModal(true);
    }
    const viewResult = () => {
        history.push(`${history.location.pathname}/results`)
    }
    const publishExam = async () => {
        await dispatch(actions.adminPublishExam(examId));
    }

    const changeQuestionType = (value: string) => {
        setQuestionType(value);
    }
    const showRuleToPublish = () => {
        setModalMode('publishRule');
        setShowModal(true);
    }

    // const showWhoCanSee = () => {
    //     history.push(`${examId}/institutes`)
    // }

    const columns = [
        {
            name: 'question_number',
            align: 'center',
            label: 'Q.NO',
            field: 'question_number',
            flexVal: 1,
            sortable: true,
            boxed: true
        },
        {
            name: 'Main Heading',
            align: 'left',
            label: 'Main Heading',
            field: 'main_heading',
            flexVal: 1,
            sortable: true,
            splice: 30,
        },
        {
            name: 'Question Heading',
            align: 'left',
            label: 'Question Heading',
            field: 'heading',
            flexVal: 1,
            sortable: true,
            splice: 50,
        },
        {
            name: 'Description',
            align: 'left',
            label: 'Description',
            field: 'description',
            flexVal: 1,
            sortable: true,
            splice: 50,
        },
        {
            name: 'Options',
            align: 'left',
            label: 'Options',
            field: ['option1', 'option2', 'option3', 'option4'],
            optionsArray: true,
            flexVal: 1,
        },
        {
            name: 'Answer',
            align: 'center',
            label: 'Answer',
            field: 'answer',
            flexVal: 1,
        }
    ]

    return (
        <section className="wrapper issues full-height full-width flex column py-md">
            <header className={'flex justify-between items-center mb-md'}>
                <div>
                    <span className="title-sm bold text-primary">List of Question</span>
                    <button className="btn primary ml-sm" onClick={viewResult}>View Results</button>
                </div>
                <div>
                    {currentExam.status === 1 ? <span className="mr-sm">Published</span> :
                        questionList.length > 1 ?
                            <button className="btn primary mr-sm" onClick={publishExam}>Publish this Exam</button> :
                            <span className="mr-sm">
                                <span>
                                    Not Ready To Publish
                                </span>
                                <span className={`text-primary underline pointer ml-xs`} onClick={showRuleToPublish}>Why?</span>
                            </span>}
                    <button className="btn primary mr-sm" onClick={addAction}>Add Questions</button>
                </div>
            </header>
            <div className="justify-between flex">
                <div className="row togle-nav pointer">
                    <div onClick={() => changeQuestionType("Reading")} className={`${questionType === "Reading" ? 'active' : ''}`}>Reading</div>
                    <div onClick={() => changeQuestionType("Listening")} className={`${questionType === "Listening" ? 'active' : ''}`}>Listening</div>
                </div>
                {/* {currentExam.type === 'private' ? <button className="ml-sm mr-sm btn primary" onClick={showWhoCanSee}>Who can see this?</button> : <div className="text-center">Everybody have access to this exam</div>} */}
                <button className="btn primary mr-sm" onClick={viewQuestionSummary}>Questions Summary</button>
            </div>

            <div className="table-area pt-md flex-1">
                <ListTable
                    columns={columns}
                    rows={questions}
                    editAction={editAction}
                    paginate={10}
                />
            </div>
            {
                modalMode === 'add' &&
                <Modal show={showModal} title={'Add Question'}
                    closeModal={() => setShowModal(false)}>
                    <AddQuestion examId={examId} closeModal={() => setShowModal(false)} mode={modalMode} questionType={questionType} />
                </Modal>}
            {
                modalMode === 'edit' &&
                <Modal show={showModal} title={'Edit Question'}
                    closeModal={() => setShowModal(false)}>
                    <EditQuestion
                        editQuestion={editQuestion}
                        editObj={editUserObj}
                        closeModal={() => setShowModal(false)}
                        mode={modalMode}
                    />
                </Modal>
            }
            {
                modalMode === 'publishRule' &&
                <Modal show={showModal} title={'Rules to Publish Exam'}
                    closeModal={() => setShowModal(false)}>
                    <ExamPublishRule
                        closeModal={() => setShowModal(false)} />
                </Modal>
            }
            {
                modalMode === 'QuestionSummary' &&
                <Modal show={showModal} title={'Question Number Summary'}
                    closeModal={() => setShowModal(false)}>
                    <QuestionSummary
                        questions={questionList}
                        closeModal={() => setShowModal(false)} />
                </Modal>
            }
            <Confirmation title="Confirm" content="Are you sure you want to delete this item?" show={showConfirmation}
                confirm={deleteConfirmation}
                closeModal={() => setShowConfirmation(false)} />
        </section>
    )
}

export default Questions;
