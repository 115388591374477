import ExamsList from '../views/ExamsList';
import Exam from '../views/Exam';
import UserProfile from '../../common/views/UserProfile';
import StudentHome from '../views/StudentHome';
import ResultList from '../views/ResultList';
// eslint-disable-next-line
export default [
  {
    path: '/profile',
    name: 'user.profile',
    component: UserProfile,
    exact: true,
  },

  {
    path: '',
    name: 'user',
    component: StudentHome,
    exact: true,
  },

  {
    path: '/exams',
    name: 'user.exams',
    component: ExamsList,
    exact: true,
  },
  {
    path: '/results',
    name: 'user.results',
    component: ResultList,
    exact: true,
  },

  {
    path: '/exams/exam/:examId',
    name: 'user.exams.exam',
    component: Exam,
    exact: true,
  },

  {
    path: '/exam/:examId',
    name: 'user.exam',
    component: ExamsList,
    exact: true,
  },
];
