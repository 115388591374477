import { setToasterState } from '../../../../../store/action';
import store from '../../../../../store/store';
import {
  AddData,
  GetCollectionData,
  GetCollectionWhereData,
} from '../../../../../plugins/firebasefirestore';

const examUrl = 'Exams';
const reportUrl = 'Reports';

export const getExamsSuccess = (payload: any) => {
  return {
    type: 'GET_EXAMS',
    payload: payload,
  };
};
export const getExamsAllSuccess = (payload: any) => {
  return {
    type: 'GET_EXAMS_ALL',
    payload: payload,
  };
};

export const getExams = () => (dispatch: any) => {
  const userID = store.getState().authReducer.user.uid;
  if (userID) {
    GetCollectionWhereData(examUrl, 'status', '==', 1)
      .then((querySnapshot) => {
        const res = querySnapshot.docs.map((doc) => doc.data());
        dispatch(getExamsSuccess(res));
      })
      .catch((err) => {
        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Fetch Error',
            message: 'Error cannot get Exams',
          })
        );
      });
  }
};
export const getExamsAll = () => (dispatch: any) => {
  const userID = store.getState().authReducer.user._id;
  if (userID) {
    GetCollectionData(examUrl)
      .then((querySnapshot) => {
        const res = querySnapshot.docs.map((doc) => doc.data());
        dispatch(getExamsAllSuccess(res));
      })
      .catch((err) => {
        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Fetch Error',
            message: 'Error cannot get Exams',
          })
        );
      });
  }
};

export const storeDataToReport = (payload: any) => (dispatch: any) => {
  const user = store.getState().authReducer.user;
  if (user.uid) {
    payload = {
      ...payload,
      username: user.email,
      user: user.uid,
    };
    AddData(reportUrl, payload)
      .then((res) => {
        // dispatch(getReportSuccess(res.data._id));
      })
      .catch((err) => {
        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Fetch Error',
            message: 'Error cannot get Exams',
          })
        );
      });
  }
};
