import { useEffect, useState } from 'react'
import { Answer } from '../../../../common/model/Answer'


interface IFinishExam {
    closeModal: Function,
    viewDetails: Function,
    answers: Answer[]
}

const FinishExam = (props: IFinishExam) => {

    const [result, setResult] = useState({
        total: 0,
        attempted: 0,
        correct: 0
    })

    useEffect(() => {
        let attempted = 0;
        let correct = 0;
        props.answers.forEach(a => {
            a.answer !== '0' && attempted++;
            a.correct && correct++
        })

        setResult({
            ...result,
            total: props.answers.length,
            attempted: attempted,
            correct: correct
        })
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.answers])

    return (
        <div className="edit-client dialog-content">
            <div className="dialog-content-area flex items-center column">
                <h1>Congratulation, Exam Ended</h1>
                <div><h1>Attempt: {result.attempted}/{result.total}</h1></div>
                <div><h1>Correct: {result.correct}/{result.total}</h1></div>
            </div>
            <div className="button-area flex justify-end mtyar-lg">
                <button className="btn primary" onClick={() => props.viewDetails()}>View Details</button>
                <button className="btn primary outlined ml-xs" onClick={() => props.closeModal()}>Go Back</button>
            </div>
        </div>
    )
}

export default FinishExam
