import React, { useState, useEffect } from 'react';
import { Exam } from '../../../../common/model/Exam';
import {  useDispatch } from 'react-redux';
import { ValidateForm, ValidateInput } from '../../../../../utils/customHooks/validateForm';
import * as action from '../services/ExamActions';
import ExamRules from './examRules'
interface IAddExam {
    closeModal: Function,
    mode: string
}

const initialState: Exam = {
    title: '',
    type: '',
    price: 0,
    status: 0,
    user: '',
}

const AddExam = (props: IAddExam) => {
    const [exam, setExam] = useState(initialState)
    const [error, setError] = useState(initialState)

    useEffect(() => {
        setExam({
            ...exam,
            type: 'private'
        });
       // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [])

    const dispatch = useDispatch();
    const inputHandler = (e: any) => {
        setError({
            ...error,
            [e.target.name]: ''
        })
        setExam({
            ...exam,
            [e.target.name]: e.target.value
        });
    }

    const confirmAction = async () => {
        let errorMsgs: any = ValidateForm(exam, ExamRules);
        setError({ ...errorMsgs });
        let validated = Object.values(errorMsgs).join('').length === 0;
        if (validated) {
            await dispatch(action.addExam(exam));
            props.closeModal();
        }
    }

    const inputValidation = (e: any) => {
        let errorMsg = ValidateInput(e.target.name, e.target.value, ExamRules);
        setError({
            ...error,
            [e.target.name]: errorMsg
        });
    }


    return (
        <div className="edit-client dialog-content">
            <div className="dialog-content-area flex">
                <div className="form-area my-md full-width">
                    <div className="form-group my-md full-width">
                        <label>Title</label>
                        <input
                            name="title"
                            type="text"
                            onChange={inputHandler}
                            onBlur={inputValidation} />
                        {error.title !== '' ? <span className="error-text">{error.title}</span> : ''}
                    </div>
                    <div className="form-group my-md full-width">
                        <label>Exam Type</label>
                        <select name="type" value={exam.type} onChange={inputHandler} onBlur={inputValidation}>
                            <option value="private">Private</option>
                            <option value="public">Public</option>
                        </select>
                        {error.type !== '' ? <span className="error-text">{error.type}</span> : ''}
                    </div>
                </div>
            </div>
            <div className="button-area flex justify-end mtyar-lg">
                <button className="btn primary" onClick={confirmAction}>Add Exam</button>
                <button className="btn primary outlined ml-xs" onClick={() => props.closeModal()}>Cancel</button>
            </div>
        </div>
    )
}

export default AddExam
