import { Answer } from '../../../../common/model/Answer';
import { Question } from '../../../../common/model/Question';
import ImageHelper from '../../../../common/component/ImageHelper';
import RadioButton from '../../../../../common/Radio';
import questionType from '../../../../common/enum/questiontype';
import soundImg from '../../../../../assets/svgs/listening.svg'
import pauseImg from '../../../../../assets/svgs/pause.svg'
import optionType from '../../../../common/enum/optionType';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

interface IDisplayQuestion {
    question: Question,
    answer: Answer,
    changePosition: Function,
    playAudio: Function,
    answerChange: Function,
    position: number
}

const DisplayQuestion = (props: IDisplayQuestion) => {

    const listeningPlay = useSelector((state: any) => {
        return state.userReducer.listeningPlay;
    });

    const [listeningImg, setListeningImg] = useState(soundImg)
    const [audioPlaying, setAudioPlaying] = useState(false);

    useEffect(() => {
        setAudioPlaying(listeningPlay)
        if (listeningPlay) {
            setListeningImg(pauseImg)
        } else {
            setListeningImg(soundImg)
        }
    }, [listeningPlay])

    return (
        <div className={'student-exam-panel flex'}>
            <div className={'question-container full-width pa-xs'} >
                <div className="my-xs">
                    {props.question?.main_heading_image ?
                        <ImageHelper
                            onClick={() => { }}
                            defaultListening={false}
                            style={{ maxWidth: '90%', maxHeight: '20rem', objectFit: 'contain' }}
                            className=""
                            image={props.question?.main_heading || ''}
                        /> :
                        <div className="question-main-heading">{props.question?.main_heading}</div>
                    }
                </div>
                {/* <div className="my-xs">
                    <div className="question-main-heading"></div>
                </div> */}
                <div className="flex full-width justify-between">
                    <div className="question-details w-75-p mr-md auto-scroll">
                        <div className="flex my-sm question-heading">
                            {`${props.position + 1}. `}  {props.question?.heading && <div className="question-heading">{props.question?.heading}</div>}
                        </div>

                        <div className="my-sm">
                            {props.question?.description_image ?
                                <ImageHelper
                                    onClick={() => { }}
                                    defaultListening={false}
                                    style={{ maxWidth: '80%', maxHeight: '15rem', minWidth: '40%', minHeight: '12rem', objectFit: 'contain' }}
                                    className=""
                                    image={props.question?.description || ''}
                                /> :
                                <div className={`question-description ${props.question.description_boxed ? 'description-boxed' : ''}`}>{props.question?.description}</div>
                            }
                        </div>
                        {props.question?.type === questionType.LISTENING &&
                            <div className="listening-file">
                                <img src={listeningImg} className="listening-icon" alt="" onClick={() => props.playAudio(props.question._id)} />
                                {audioPlaying &&
                                    <audio className="listening-element" autoPlay={true}>
                                        <source src={props.question?.listening}></source>
                                    </audio>}
                            </div>}
                    </div>

                    <div className="options-container flex column w-35-p ml-md mb-md justify-between" style={{ height: '60vh' }}>
                        <div className="auto-scroll">
                            <label className="radio-btn-container flex items-center">
                                <RadioButton
                                    onImageClick={() => props.playAudio(props.question._id)}
                                    image={props.question.option_type || ''}
                                    onBlur={() => { }}
                                    butttonNumber={'1'}
                                    checked={props.answer?.answer === '1'}
                                    name={"answer"}
                                    value={"1"}
                                    onChange={(e: any) => props.answerChange(e)}
                                    text={
                                        props.question.option_type === optionType.LISTENING ? '' :
                                            (props.question?.option1 || '')}
                                />
                            </label>
                            <label className="radio-btn-container flex items-center">
                                <RadioButton
                                    onImageClick={() => props.playAudio(props.question._id)}
                                    image={props.question.option_type || ''}
                                    onBlur={() => { }}
                                    butttonNumber={'2'}
                                    checked={props.answer?.answer === '2'}
                                    name={"answer"}
                                    value={"2"}
                                    onChange={(e: any) => props.answerChange(e)}
                                    text={
                                        props.question.option_type === optionType.LISTENING ? '' :
                                            (props.question?.option2 || '')}
                                />

                            </label>
                            <label className="radio-btn-container flex items-center">

                                <RadioButton
                                    onImageClick={() => props.playAudio(props.question._id)}
                                    image={props.question.option_type || ''}
                                    onBlur={() => { }}
                                    butttonNumber={'3'}
                                    checked={props.answer?.answer === '3'}
                                    name={"answer"}
                                    value={"3"}
                                    onChange={(e: any) => props.answerChange(e)}
                                    text={
                                        props.question.option_type === optionType.LISTENING ? '' :
                                            (props.question?.option3 || '')}
                                />

                            </label>
                            <label className="radio-btn-container flex items-center">
                                <RadioButton
                                    onImageClick={() => props.playAudio(props.question._id)}
                                    image={props.question.option_type || ''}
                                    onBlur={() => { }}
                                    butttonNumber={'4'}
                                    checked={props.answer?.answer === '4'}
                                    name={"answer"}
                                    value={"4"}
                                    onChange={(e: any) => props.answerChange(e)}
                                    text={
                                        props.question.option_type === optionType.LISTENING ? '' :
                                            (props.question?.option4 || '')}
                                />
                            </label>
                        </div>

                        <div className={`flex justify-center mt-md`}>
                            <div className="flex option-btn pa-sm justify-between items-center flex-1 border-1px" onClick={() => props.changePosition(-1)}>
                                <i className="material-icons">chevron_left</i>
                                Previous Question
                            </div>
                            <div className="flex option-btn pa-sm justify-between items-center flex-1 border-1px" onClick={() => props.changePosition(1)}>
                                Next Question
                                <i className="material-icons">chevron_right</i>
                            </div>
                        </div>

                    </div>
                </div>



            </div>


            {/* <AnswerList
                        answers={qType === questionType.LISTENING ? listeningAnswer : readingAnswer}
                        flex={1}
                        qType={qType} /> */}
        </div >
    )
}

export default DisplayQuestion
