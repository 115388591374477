import { Reducer, combineReducers } from 'redux';
import modalReducer from '../common/modal/services/modalReducer';
import loadingReducer from '../common/loading/services/loadingReducer';
import toasterReducer from '../common/toaster/services/toasterReducer';
import AdminReducer from '../modules/admin/store/AdminReducers';
import AuthReducers from "../modules/auth/store/AuthReducers";
import UserReducer from "../modules/student/store/UserReducers";

const rootReducer: Reducer = combineReducers({
    modalReducer: modalReducer,
    loadingReducer: loadingReducer,
    toasterReducer: toasterReducer,
    authReducer: AuthReducers,
    userReducer: UserReducer,
    adminReducer: AdminReducer,
})

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
