// import * as actions from '../../../../../store/action';
import { useEffect, useState } from 'react';
import { Answer } from '../../../../common/model/Answer';
import Timer from './Timer';

interface IExamHeading {
    totalClick: Function,
    finishExamTodo: Function,
    answers: Answer[]
}

const ExamHeading = (props: IExamHeading) => {
    const [result, setResult] = useState({
        total: 0,
        solved: 0,
    })

    useEffect(() => {
        let solved = 0;
        props.answers.forEach(a => {
            a.answer !== '0' && solved++;
        })

        setResult({
            ...result,
            total: props.answers.length,
            solved: solved,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.answers])

    return (
        <div className="exam-header flex justify-between pa-sm my-sm mb-md full-width bg-primary">
            <div className="secondary total-questions text-light pointer bold" onClick={() => props.totalClick()}>Total Questions: {result.total}</div>
            <div className="solved text-light bold">Solved: {result.solved}</div>
            <div className="unsolved text-light bold">UnSolved: {result.total - result.solved}</div>
            <Timer finishExam={props.finishExamTodo} />
        </div>
    )
}

export default ExamHeading
