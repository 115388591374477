import React, { useEffect, useState } from 'react';
import ToggleSwitch from '../../../../../common/ToggleSwitch';
import { ValidateForm, ValidateInput } from '../../../../../utils/customHooks/validateForm';
import { Question } from '../../../../common/model/Question';
import RadioButton from '../../../../../common/Radio';
import PreviewQuestionAdd from './PreviewQuestionAdd';
import QuestionRulesReading from './questionRulesReading';
import QuestionRuleListening from './questionRuleListening';
import questionType from '../../../../common/enum/questiontype';
import optionType from '../../../../common/enum/optionType';
import Loading from '../../../../../common/loading';
import { useSelector } from 'react-redux';

interface IEditQuestion {
    editObj: any,
    mode: string,
    closeModal: Function,
    editQuestion: Function,
}

const initialState: Question = {
    main_heading: '',
    heading: '',
    description: '',
    description_boxed: false,
    option1: '',
    option2: '',
    option3: '',
    option4: '',
    listening: '',
    answer: '',
    answer_details: '',
}

const imageState: any = {
    main_heading: null,
    description: null,
    option1: null,
    option2: null,
    option3: null,
    option4: null,
};

const EditQuestion = (props: IEditQuestion) => {
    const [question, setQuestion] = useState(initialState);
    const [image, setImage] = useState(imageState);
    const [localImage, setLocalImage] = useState(imageState);
    const [errors, setErrors] = useState(initialState);
    const loading = useSelector((state: any) => state.adminReducer.questionAddLoad);

    useEffect(() => {
        if (props.mode === 'edit') {
            setQuestion(props.editObj)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.editObj]);

    useEffect(() => {
        if (image) {
            Object.keys(image).forEach((key: any, index: number) => {
                if (image[key]) {
                    getBase64(key, image[key], (imageString: string) => {
                        setLocalImage({
                            ...localImage,
                            [key]: imageString
                        })

                        setQuestion({
                            ...question,
                            [key]: imageString
                        })
                    });
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [image])

    const getBase64 = (key: string, file: any, cb: Function) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            setErrors({
                ...errors,
                [key]: 'Invalid Image. Error Adding Image'
            });
        };
    }

    const inputHandler = (e: any) => {
        setErrors({
            ...errors,
            [e.target.name]: '',
        });
        setQuestion({
            ...question,
            [e.target.name]: e.target.name === "listening" ? e.target.files[0] : e.target.value,
        });
    };

    const confirmAction = async () => {
        if (question.option_type === optionType.LISTENING) {
            question.option1 = 'Listening'
            question.option2 = 'Listening'
            question.option3 = 'Listening'
            question.option4 = 'Listening'
        }
        let errorMsgs: any = ValidateForm(question, question.type === questionType.READING ? QuestionRulesReading : typeof question?.listening === "string" ? QuestionRulesReading : QuestionRuleListening);
        setErrors({ ...errorMsgs });
        let validated = Object.values(errorMsgs).join('').length === 0;
        if (validated) {
            await props.editQuestion(question, image);
            props.closeModal();
        }
    }

    const inputValidation = (e: any) => {
        let errorMsg = ValidateInput(e.target.name,
            e.target.name === "listening" ? e.target.files[0] : e.target.value,
            QuestionRulesReading);
        setErrors({
            ...errors,
            [e.target.name]: errorMsg,
        });
    };

    const inputImageHandler = (e: any) => {
        setErrors({
            ...errors,
            [e.target.name]: ''
        })
        setImage({
            ...image,
            [e.target.name]: e.target.files[0]
        })
    }

    const toggleImage = (e: any) => {
        setQuestion({
            ...question,
            [e.target.name]: e.target.checked
        });
    }

    return (
        <div className="edit-client dialog-content">
            <div className="flex ">
                <div style={{ maxHeight: "70vh" }} className="dialog-content-area ma-sm pa-sm auto-scroll">
                    <div className="form-group my-md">
                        <label>Question Number</label>
                        <input
                            type="text"
                            name={'question_number'}
                            onBlur={inputValidation}
                            value={question.question_number}
                            onChange={inputHandler}
                        />
                        {errors.heading !== '' ? (
                            <span className="error-text">{errors.heading}</span>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="form-group my-sm ">
                        <div className="flex items-center">
                            <label className="pr-sm">Main Heading</label>
                            <ToggleSwitch
                                checked={question.main_heading_image || false}
                                name="main_heading_image"
                                onChange={toggleImage}
                            />
                        </div>
                        {question.main_heading_image ?
                            <input
                                type="file"
                                accept={'image/x-png,image/jpg,image/jpeg'}
                                name={'main_heading'}
                                onBlur={inputValidation}
                                onChange={inputImageHandler}
                            />
                            : <input
                                type="text"
                                name={'main_heading'}
                                onBlur={inputValidation}
                                value={question.main_heading}
                                onChange={inputHandler}
                            />}

                        {errors.main_heading !== '' ? <span className="error-text">{errors.main_heading}</span> : ''}
                    </div>
                    <div className="form-group my-md">
                        <label>Question Heading</label>
                        <input
                            type="text"
                            name={'heading'}
                            onBlur={inputValidation}
                            value={question.heading}
                            onChange={inputHandler}
                        />
                        {errors.heading !== '' ? <span className="error-text">{errors.heading}</span> : ''}
                    </div>
                    <div className="form-group my-md">
                        <div className="flex items-center">
                            <label className="pr-sm">Description</label>
                            <ToggleSwitch
                                checked={question.description_image || false}
                                name="description_image"
                                onChange={toggleImage}
                            />
                        </div>
                        <div className="flex items-center">
                            <label className="pr-sm">Description Boxed</label>
                            <ToggleSwitch
                                checked={question.description_boxed || false}
                                name="description_boxed"
                                onChange={toggleImage}
                            />
                        </div>
                        {question.description_image ?
                            <input
                                type="file"
                                accept={'image/x-png,image/jpg,image/jpeg'}
                                name={'description'}
                                onBlur={inputValidation}
                                onChange={inputImageHandler}
                            />
                            :
                            <input
                                type="text"
                                name={'description'}
                                onBlur={inputValidation}
                                value={question.description}
                                onChange={inputHandler}
                            />}
                        {errors.description !== '' ? <span className="error-text">{errors.description}</span> : ''}
                    </div>
                    <div className="form-group mb-lg">
                        <label className={'bold text-primary'}>Option Type</label>
                        <select name="option_type"
                            value={question.option_type}
                            onBlur={inputValidation}
                            onChange={inputHandler}>
                            <option value={optionType.TEXT}>Text</option>
                            <option value={optionType.IMAGE}>Image</option>
                            <option value={optionType.LISTENING}>Listening</option>
                        </select>

                        {errors.option_type !== '' ? (
                            <span className="error-text">{errors.option_type}</span>
                        ) : (
                            ''
                        )}
                    </div>

                    <div className="form-group my-md">
                        <label>Option 1</label>
                        <div className="flex align-center">
                            <RadioButton
                                onImageClick={() => { }}
                                image={''}
                                checked={question.answer === '1'}
                                butttonNumber={'1'}
                                name={'answer'}
                                onChange={inputHandler}
                                onBlur={inputValidation}
                                text={''}
                                value={'1'} />
                            {question.option_type === optionType.IMAGE && (
                                <input
                                    className="mx-sm qwe-options"
                                    type="file"
                                    accept={'image/x-png,image/jpg,image/jpeg'}
                                    name={'option1'}
                                    onBlur={inputValidation}
                                    onChange={inputImageHandler}
                                />
                            )}
                            {question.option_type === optionType.TEXT && (
                                <input
                                    className="mx-sm qwe-options"
                                    type="text"
                                    name={'option1'}
                                    onBlur={inputValidation}
                                    value={question.option1}
                                    onChange={inputHandler}
                                />
                            )}
                        </div>
                        {errors.option1 !== '' ? (
                            <span className="error-text">{errors.option1}</span>
                        ) : (
                            ''
                        )}
                    </div>

                    <div className="form-group my-md">
                        <label>Option 2</label>
                        <div className="flex align-center">
                            <RadioButton
                                onImageClick={() => { }}
                                image={''}
                                checked={question.answer === '2'}
                                butttonNumber={'2'}
                                name={'answer'}
                                onChange={inputHandler}
                                onBlur={inputValidation}
                                text={''}
                                value={'2'} />
                            {question.option_type === optionType.IMAGE && (
                                <input
                                    className="mx-sm qwe-options"
                                    type="file"
                                    accept={'image/x-png,image/jpg,image/jpeg'}
                                    name={'option2'}
                                    onBlur={inputValidation}
                                    onChange={inputImageHandler}
                                />
                            )}
                            {question.option_type === optionType.TEXT && (
                                <input
                                    type="text"
                                    name={'option2'}
                                    onBlur={inputValidation}
                                    className="mx-sm qwe-options"
                                    value={question.option2}
                                    onChange={inputHandler}
                                />
                            )}
                        </div>
                        {errors.option2 !== '' ? (
                            <span className="error-text">{errors.option2}</span>
                        ) : (
                            ''
                        )}
                    </div>

                    <div className="form-group my-md">
                        <label>Option 3</label>
                        <div className="flex align-center">
                            <RadioButton
                                onImageClick={() => { }}
                                image={''}
                                checked={question.answer === '3'}
                                butttonNumber={'3'}
                                name={'answer'}
                                onChange={inputHandler}
                                onBlur={inputValidation}
                                text={''}
                                value={'3'} />
                            {question.option_type === optionType.IMAGE && (
                                <input
                                    type="file"
                                    accept={'image/x-png,image/jpg,image/jpeg'}
                                    name={'option3'}
                                    onBlur={inputValidation}
                                    onChange={inputImageHandler}
                                    className="mx-sm qwe-options"
                                />
                            )}
                            {question.option_type === optionType.TEXT && (
                                <input
                                    type="text"
                                    name={'option3'}
                                    onBlur={inputValidation}
                                    value={question.option3}
                                    onChange={inputHandler}
                                    className="mx-sm qwe-options"
                                />
                            )}
                        </div>
                        {errors.option3 !== '' ? (
                            <span className="error-text">{errors.option3}</span>
                        ) : (
                            ''
                        )}
                    </div>

                    <div className="form-group my-md">
                        <label>Option 4</label>
                        <div className="flex align-center">
                            <RadioButton
                                onImageClick={() => { }}
                                image={''}
                                checked={question.answer === '4'}
                                butttonNumber={'4'}
                                name={'answer'}
                                onChange={inputHandler}
                                onBlur={inputValidation}
                                text={''}
                                value={'4'} />
                            {question.option_type === optionType.IMAGE && (
                                <input
                                    className="mx-sm qwe-options"
                                    type="file"
                                    accept={'image/x-png,image/jpg,image/jpeg'}
                                    name={'option4'}
                                    onBlur={inputValidation}
                                    onChange={inputImageHandler}
                                />
                            )}
                            {question.option_type === optionType.TEXT && (
                                <input
                                    type="text"
                                    name={'option4'}
                                    className="mx-sm qwe-options"
                                    onBlur={inputValidation}
                                    value={question.option4}
                                    onChange={inputHandler}
                                />
                            )}
                        </div>

                        {errors.option4 !== '' ? (
                            <span className="error-text">{errors.option4}</span>
                        ) : (
                            ''
                        )}
                    </div>

                    {question.type === 'Listening' &&
                        <div className="form-group my-md">
                            <label>listening(max size = 1MB)</label>
                            <input
                                type="file"
                                name={'listening'}
                                onBlur={inputValidation}
                                onChange={inputHandler}
                                accept={'.mp3,audio/*'}
                            />
                            {errors.listening !== '' ?
                                <span className="error-text">{errors.listening}</span> : ''}
                        </div>}


                </div>
                <PreviewQuestionAdd
                    localImage={localImage}
                    question={question}
                />

            </div>
            <div className="button-area flex justify-end mt-lg">
                <button className="btn primary" onClick={confirmAction} disabled={loading}>
                    {!loading ? <span>Add Question</span> : <Loading isLoading={true} />}
                </button>
                <button className="btn primary outlined ml-xs" onClick={() => props.closeModal()}>Cancel</button>
            </div>
        </div>
    )
}

export default EditQuestion
