import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../store/action";
import { Prompt, useParams } from "react-router";
import { Question } from "../../../common/model/Question";
import questionType from "../../../common/enum/questiontype";
import "./exam.scss";
import { Answer } from "../../../common/model/Answer";
import Modal from "../../../../common/modal";
import FinishExam from "./components/FinishExam";
import { useHistory } from "react-router-dom";
import ExamHeading from "./components/ExamHeading";
import QuestionNumberLists from "./components/QuestionNumberList";
import DisplayQuestion from "./components/DisplayQuestion";
import DisplayQuestionPdf from "./components/DisplayQuestionPdf";
import Confirmation from "../../../../common/modal/Confirmation";
import { Exam } from '../../../common/model/Exam';

const Exams = () => {
  const questionsList: Question[] = useSelector((state: any) => state.userReducer.questions);
  const answersList: Answer[] = useSelector((state: any) => state.userReducer.answers);
  const questionPosition = useSelector((state: any) =>
    state.userReducer.currentQuestion
  );
  const examList = useSelector((state: any) => {
    return state.userReducer.exams;
  });
  const listeningStatus = useSelector((state: any) => state.userReducer.listening_status);

  const examRunning: boolean = useSelector((state: any) => state.userReducer.examRunning);
  const { examId } = useParams<{ examId: string }>();
  const [questions, setQuestions] = useState<Question[]>([]);
  const [answer, setAnswer] = useState<Answer[]>([]);
  const user = useSelector((state: any) => state.authReducer.user);
  const [position, setPosition] = useState(0);
  const [examMode, setExamMode] = useState("all");
  const [modalMode, setModalMode] = useState("finishExam");
  const [showModal, setShowModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentExam, setCurrentExam] = useState<Exam>({})
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("runningExam") === examId && examRunning) {
      dispatch(actions.setQuestionType(questionType.READING));
      user.uid && loadQuestions();
    } else {
      returnBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  useEffect(() => {
    if (examId) {
      const exam: Exam = examList.find((exam: Exam) => exam._id === examId)
      exam ? setCurrentExam(exam) : returnBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [examList])

  const returnBack = () => {
    dispatch(actions.setExamRunning(false));
    removeLocalStorageValue();
    history.push("/student/exams");
  };
  const removeLocalStorageValue = () => {
    localStorage.removeItem("runningExam");
  };

  useEffect(() => {
    removeCheckedFromRadio();
    questionPosition >= 0 && setPosition(questionPosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionPosition]);

  useEffect(() => {
    dispatch(actions.setQuestionPosition(position));
    setQuestions(questionsList);
    let answers: any = [];
    questionsList.forEach((q) => {
      answers = [
        ...answers,
        { question: q._id, answer: "0", type: q.type, correct: 0, question_number: q.question_number },
      ];
    });
    dispatch(actions.setAnswers(answers));
    let a: any = [];
    questionsList
      .filter((q) => q.type === questionType.LISTENING)
      .forEach((q) => {
        a = [...a, { question: q._id, status: true }];
      });
    setListeningStatus(a);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionsList]);

  useEffect(() => {
    setAnswer(answersList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answersList]);

  useEffect(() => {
    if (examRunning) {
      window.onbeforeunload = () => true;
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [examRunning]);

  // const loadListening = async () => {
  //     await dispatch(actions.getStudentExamListening(examId));
  // }

  const playAudio = () => {
    const audioEl: any = document.getElementsByClassName("audio-element")[0];
    audioEl.play();
  };

  const setListeningStatus = async (payload: any) => {
    await dispatch(actions.setListeningStatus(payload));
  };

  const playAudioSwitch = async (questionId: string) => {
    const obj = listeningStatus.find((q: any) => q.question === questionId);
    obj && obj.status && (await dispatch(actions.setListeningPlaying(true)));
    changeStatusToFalse(questionId);
    return obj && obj.status
  };

  const changeStatusToFalse = async (questionId: string) => {
    const newlS = listeningStatus.map((l: any) =>
      l.question === questionId ? { ...l, status: false } : l
    );
    setListeningStatus(newlS);
  };

  const loadQuestions = async () => {
    examId && (await dispatch(actions.getQuestions(examId)));
  };

  const removeCheckedFromRadio = () => {
    const radios = document.querySelectorAll("#answer");
    radios &&
      radios.forEach((r: any) => {
        r.checked = false;
      });
  };
  const switchToSingleMode = (position: number) => {
    setExamMode("single");
    changePosition(position);
  };

  const switchToAllMode = async () => {
    setExamMode("all");
    // await dispatch(actions.setListeningPlaying(false))
  };
  const changePosition = async (num: number) => {
    playAudio();
    await dispatch(actions.setListeningPlaying(false));
    num >= 0 && num < questions.length && setPosition(num);
    num >= 0 &&
      num < questions.length &&
      (await dispatch(actions.setQuestionPosition(num)));
  };

  const changePositionByOne = async (num: number, allow: boolean = false) => {
    playAudio();
    await dispatch(actions.setListeningPlaying(false));
    position + num !== -1 &&
      position + num <= questions.length - 1 &&
      setPosition(position + num);
    position + num !== -1 &&
      position + num <= questions.length - 1 &&
      (await dispatch(actions.setQuestionPosition(position + num)));
  };

  // const changeReadingListening = async (type: string) => {
  //     await dispatch(actions.setQuestionType(type));
  // }

  const uploadAnswer = async () => {
    const uploadPayload = {
      answers: answer,
    };
    await dispatch(actions.uploadAnswer(examId, uploadPayload, currentExam.title));
  };
  const answerChange = async (e: any) => {
    const newAnswers = await answersList.map((a, i) =>
      a.question === questions[position]._id
        ? {
          ...a,
          answer: e.target.value,
          correct: e.target.value === questions[position].answer,
        }
        : a
    );
    dispatch(actions.setAnswers(newAnswers));
  };
  const stopAudio = () => {
    const audio: any = document.querySelector("#exam-listening");
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
    }
  };

  const finishExamTodo = () => {
    stopAudio();
    removeLocalStorageValue();
    setShowConfirmation(false);
    uploadAnswer();
    dispatch(actions.setExamRunning(false));
    setModalMode("finishExam");
    setShowModal(true);
  };

  const submitExamDialog = () => {
    setModalMode("endExam");
    setShowConfirmation(true);
  };
  const closeModal = () => {
    setShowModal(false);
    history.push("/student/exams");
  };
  const viewResult = () => {
    setShowModal(false);
    history.push("/student/results");
  };

  return (
    <section className=" issues full-height full-width flex column pa-md">
      <header className={"flex justify-end items-center "}>
        <ExamHeading finishExamTodo={finishExamTodo} totalClick={switchToAllMode} answers={answer} />
      </header>


      <audio className="audio-element">
        <source src="https://assets.coderrocketfuel.com/pomodoro-times-up.mp3"></source>
      </audio>
      {/* {
                qType === questionType.LISTENING && listeningObj?.audio &&
                <div>
                    <audio id="exam-listening" src={`${process.env.REACT_APP_API_BASE_URL}${listeningObj?.audio}`}
                        preload="auto"
                        autoPlay={true}
                        controls={true}
                    ></audio>
                </div>
            } */}

      {examMode === "single" && questions.length > 0 && (
        <DisplayQuestion
          answer={answer[position]}
          question={questions[position]}
          answerChange={answerChange}
          changePosition={changePositionByOne}
          playAudio={playAudioSwitch}
          position={position}
        />
      )}
      <div className="flex column">
        <div className="flex row questions">
          {examMode === "all" && (
            <QuestionNumberLists
              setListeningStatus={setListeningStatus}
              changeMode={switchToSingleMode}
            />
          )}
          {examMode === "all" && (
            <div
              className="questions-pdf mt-md auto-scroll pa-sm"
              style={{ maxHeight: "58vh" }}
            >
              {questionsList.map((que: any, i: number) => (
                <DisplayQuestionPdf position={i} question={que} />
              ))}
            </div>
          )}
        </div>
        {examMode === "all" && (
          <div className="flex py-md row justify-end">
            <button className="btn primary" onClick={submitExamDialog}>Submit and Finish Exam</button>
          </div>
        )}
      </div>
      {modalMode === "finishExam" && (
        <Modal
          show={showModal}
          title={"Time Ended - Exam Summary"}
          closeModal={closeModal}
        >
          <FinishExam
            viewDetails={viewResult}
            closeModal={closeModal}
            answers={answersList}
          />
        </Modal>
      )}

      {modalMode === "endExam" && (
        <Confirmation
          title="Confirm"
          content="Time is still remaining , Are you sure want to submit and end exam?"
          show={showConfirmation}
          confirm={finishExamTodo}
          closeModal={() => setShowConfirmation(false)}
        />
      )}
      <Prompt
        when={examRunning}
        message="Exam is Running, it will affect the unsaved changes, Are you sure want to quit?"
      />
    </section>
  );
};

export default Exams;
