import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import questionType from '../../../../common/enum/questiontype';
import { Answer } from '../../../../common/model/Answer';

interface IQuestionNumberLists {
    changeMode: Function;
    setListeningStatus: Function;
}

const QuestionNumberLists = (props: IQuestionNumberLists) => {

    const answers: Answer[] = useSelector((state: any) => {
        return state.userReducer.answers;
    });
    const [listenings, setListening] = useState<Answer[]>([])
    const [readings, setReadings] = useState<Answer[]>([])

    useEffect(() => {
        setListening(answers.filter(q => q.type === questionType.LISTENING))
        setReadings(answers.filter(q => q.type === questionType.READING))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [answers])


    return (
        <div className="question-list">
            <div className="questions-container">
                Reading Questions
                <div>
                    {readings.map((q, key) => (
                        <div onClick={() => props.changeMode(key)} className={`text-light pointer ${q.answer !== '0' ? 'bg-danger' : 'bg-primary '} `}>
                            {q.question_number}
                        </div>
                    ))}
                </div>
            </div>

            <div className="questions-container">
                Listening Questions
                <div>
                    {listenings.map((q, key) => (
                        <div onClick={() => props.changeMode(readings.length + key)}
                            className={`text-light pointer ${q.answer !== '0' ? 'bg-danger' : 'bg-primary '} `}>
                            {q.question_number}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default QuestionNumberLists;
