import React from 'react';
import UserRoute from "./routes";
import { useLocation } from "react-router";
import NavClient from '../common/hoc/containers/Nav';
import ClientSidebar from '../common/hoc/containers/Sidebar';

const UserLayout: React.FC = () => {
    const location = useLocation();

    const noSideBar = () => {
        let loc = location.pathname.split('/').filter(v => v !== "");
        const isUserHome = loc.length === 1 && loc[0] === 'User';
        const isMenu = loc[2] === 'menu';
        const isProfile = loc[1] === 'profile';
        const isExam = loc[0] === 'student' && loc[1] === 'exams' && loc[2] === 'exam';
        return isUserHome || isMenu || isProfile || isExam;
    }

    return (
        <>
            <main className="admin full-vh full-width">
                <section className="container-dashboard full-height full-width flex column">
                    <NavClient />
                    <div className="main-content flex-1 flex full-width">
                        {!noSideBar() ? <ClientSidebar /> : ''}
                        <div className="content-area flex-1 overflow-y-auto">
                            <UserRoute />
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};

export default UserLayout;
