import { setToasterState } from '../../../../../store/action';
import store from '../../../../../store/store';
import {
  AddData,
  GetCollectioninSortWhereData,
} from '../../../../../plugins/firebasefirestore';

const questionUrl = 'Questions';
const resultUrl = 'Results';
export const getQuestionsSuccess = (payload: any) => {
  return {
    type: 'GET_QUESTIONS',
    payload: payload,
  };
};

export const setAnswers = (payload: any) => {
  return {
    type: 'SET_ANSWERS',
    payload: payload,
  };
};

export const setQuestionPosition = (payload: number) => {
  return {
    type: 'SET_QUESTION_POSITION',
    payload: payload,
  };
};
export const setQuestionType = (payload: string) => {
  return {
    type: 'SET_QUESTION_TYPE',
    payload: payload,
  };
};
export const setExamRunning = (payload: boolean) => {
  return {
    type: 'SET_EXAM_RUNNING',
    payload: payload,
  };
};

export const setListeningPlaying = (payload: boolean) => {
  return {
    type: 'GET_LISTENING_PLAYING',
    payload: payload,
  };
};
export const setListeningStatus = (payload: any) => {
  return {
    type: 'GET_LISTENING_STATUS',
    payload: payload,
  };
};

export const setReportSuccess = (payload: string) => {
  return {
    type: 'GET_REPORT_ID',
    payload: payload,
  };
};

export const uploadAnswer = (examId: string, payload: any, examTitle: any) => (
  dispatch: any
) => {
  const user = store.getState().authReducer.user;
  if (user.uid) {
    payload = {
      ...payload,
      user: user.uid,
      username: user.email,
      exam: examId,
      title: examTitle,
    };

    AddData(resultUrl, payload)
      .then((res) => {
        dispatch(
          setToasterState({
            appear: true,
            title: 'success',
            name: 'Success',
            message: 'Result Saved Sucessfully',
          })
        );
      })
      .catch((err) => {
        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Fetch Error',
            message: 'Error cannot save result',
          })
        );
      });
  }
};

export const getQuestions = (examId: string) => (dispatch: any) => {
  const userID = store.getState().authReducer.user.uid;
  if (userID) {
    GetCollectioninSortWhereData(
      questionUrl,
      'question_number',
      'exam',
      '==',
      examId
    )
      .then((querySnapshot) => {
        const res = querySnapshot.docs.map((doc) => doc.data());
        dispatch(getQuestionsSuccess(res));
      })
      .catch((err) => {
        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Fetch Error',
            message: 'Error cannot get Questions',
          })
        );
      });
  }
};
