import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../store/action';
import { useHistory } from "react-router-dom";
import { Exam } from '../../../common/model/Exam'
import Modal from '../../../../common/modal';
import ExamsRule from './components/ExamsRule';
import ExamCard from '../../../../common/ExamCard';

const ExamsList = () => {
    const examsList = useSelector((state: any) => {
        return state.userReducer.exams;
    });
    const examsListAll = useSelector((state: any) => {
        return state.userReducer.allExams;
    });
    const history = useHistory();
    const user = useSelector((state: any) => state.authReducer.user);
    const dispatch = useDispatch();
    const [exams, setExams] = useState([]);
    const [exam, setExam] = useState<Exam>({});
    const [showModal, setShowModal] = useState(false);
    const [modalMode, setModalMode] = useState('viewRules');

    useEffect(() => {
        user.uid && loadExams();
        user.uid && loadExamsAll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        setExams(examsList);
    }, [examsList])

    const loadExams = async () => {
        await dispatch(actions.getExams());
    }

    const loadExamsAll = async () => {
        await dispatch(actions.getExamsAll());
    }

    const viewExam = async () => {
        if (exam && exam._id) {
            localStorage.setItem('runningExam', exam._id || '')
            const payload = {
                exam: exam._id,
                examTitle: exam.title,
                user: user.uid,
            }
            await dispatch(actions.storeDataToReport(payload));
            dispatch(actions.setExamRunning(true));
            history.push(`exams/exam/${exam._id}`)
        }
    }


    const viewExamRules = (obj: Exam) => {
        setExam(obj);
        setModalMode('viewRules')
        setShowModal(true)
    }

    return (
        <section className="wrapper issues full-height full-width flex column py-md">
            <header className={'flex justify-between items-center mb-md'}>
                <div className="title-sm bold text-primary">Available Exams</div>
            </header>
            <div className="flex wrap">
                {exams.length > 0 ? exams.map((e: any) => <ExamCard
                    exam={e}
                    title={e.title}
                    startAction={viewExamRules}
                />) : (
                    <div>No Exams Found</div>
                )}
            </div>

            <header className={'flex justify-between items-center mb-md'}>
                <div className="title-sm bold text-primary">Other Exams List</div>
            </header>
            <div className="flex wrap">
                {examsListAll.length > 0 ? examsListAll.map((e: any) => <ExamCard
                    exam={e}
                    title={e.title}
                />) : (
                    <div>No Exams Found</div>
                )}
            </div>
            {
                modalMode === 'viewRules' &&
                <Modal show={showModal} title={'Exam Rules'}
                    closeModal={() => setShowModal(false)}>
                    <ExamsRule closeModal={() => setShowModal(false)} confirmAction={viewExam} />
                </Modal>
            }
        </section>
    )
}

export default ExamsList;
