import * as actionTypes from './UserActionTypes';

const initialState: any = {
  exams: [],
  allExams: [],
  questions: [],
  answers: [],
  examRunning: false,
  results: [],
  listening_status: [],
  questionType: 'Reading',
  listeningPlay: false,
  currentQuestion: -1,
};
// eslint-disable-next-line
export default (state: any = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.GET_EXAMS:
      return {
        ...state,
        exams: action.payload,
      };
    case actionTypes.GET_EXAMS_ALL:
      return {
        ...state,
        allExams: action.payload,
      };
    case actionTypes.GET_QUESTIONS:
      return {
        ...state,
        questions: action.payload,
      };
    case actionTypes.SET_QUESTION_POSITION:
      return {
        ...state,
        currentQuestion: action.payload,
      };
    case actionTypes.SET_QUESTION_TYPE:
      return {
        ...state,
        questionType: action.payload,
      };
    case actionTypes.SET_ANSWERS:
      return {
        ...state,
        answers: action.payload,
      };
    case actionTypes.GET_LISTENING_PLAYING:
      return {
        ...state,
        listeningPlay: action.payload,
      };
    case actionTypes.GET_LISTENING_STATUS:
      return {
        ...state,
        listening_status: action.payload,
      };
    case actionTypes.GET_RESULTS:
      return {
        ...state,
        results: action.payload,
      };
    case actionTypes.SET_EXAM_RUNNING:
      return {
        ...state,
        examRunning: action.payload,
      };
    default:
      return state;
  }
};
