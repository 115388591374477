import { Question } from '../../../../common/model/Question';
import ImageHelper from '../../../../common/component/ImageHelper';
import RadioButton from '../../../../../common/Radio';
import questionType from '../../../../common/enum/questiontype';
import soundImg from '../../../../../assets/svgs/listening.svg'
import optionType from '../../../../common/enum/optionType';

interface IDisplayQuestionPdf {
    question: Question,
    position: number,
}

const DisplayQuestionPdf = (props: IDisplayQuestionPdf) => {

    return (
        <div className={'student-exam-panel flex'}>
            <div className={'question-container full-width pa-xs'} >
                <div className="my-xs">
                    {props.question?.main_heading_image ?
                        <ImageHelper
                            onClick={() => { }}
                            defaultListening={false}
                            style={{ maxWidth: '90%', maxHeight: '20rem', objectFit: 'contain' }}
                            className=""
                            image={props.question?.main_heading || ''}
                        /> :
                        <div className="question-main-heading">{props.question?.main_heading}</div>
                    }
                </div>
                {/* <div className="my-xs">
                    <div className="question-main-heading"></div>
                </div> */}
                <div className="flex full-width justify-between">
                    <div className="question-details w-75-p mr-md auto-scroll">
                        <div className="flex my-sm question-heading">
                            {`${props.position + 1}. `}  {props.question?.heading && <div className="question-heading">{props.question?.heading}</div>}
                        </div>

                        <div className="my-sm">
                            {props.question?.description_image ?
                                <ImageHelper
                                    onClick={() => { }}
                                    defaultListening={false}
                                    style={{ maxWidth: '80%', maxHeight: '15rem', minWidth: '40%', minHeight: '12rem', objectFit: 'contain' }}
                                    className=""
                                    image={props.question?.description || ''}
                                /> :
                                <div className={`question-description ${props.question.description_boxed ? 'description-boxed' : ''}`}>{props.question?.description}</div>
                            }
                        </div>
                        {props.question?.type === questionType.LISTENING &&
                            <div className="listening-file">
                                <img src={soundImg} className="listening-icon" alt="" />
                            </div>}
                    </div>

                    <div className="options-container flex column w-35-p ml-md mb-md auto-scroll justify-between">
                        <div>
                            <label className="radio-btn-container flex items-center">
                                <RadioButton
                                    onImageClick={() => { }}
                                    image={props.question.option_type || ''}
                                    onBlur={() => { }}
                                    butttonNumber={'1'}
                                    checked={false}
                                    name={"answer"}
                                    value={"1"}
                                    onChange={() => { }}
                                    text={
                                        props.question.option_type === optionType.LISTENING ? '' :
                                            (props.question?.option1 || '')}
                                />
                            </label>
                            <label className="radio-btn-container flex items-center">
                                <RadioButton
                                    onImageClick={() => { }}
                                    image={props.question.option_type || ''}
                                    onBlur={() => { }}
                                    butttonNumber={'2'}
                                    checked={false}
                                    name={"answer"}
                                    value={"2"}
                                    onChange={() => { }}
                                    text={
                                        props.question.option_type === optionType.LISTENING ? '' :
                                            (props.question?.option2 || '')}
                                />

                            </label>
                            <label className="radio-btn-container flex items-center">

                                <RadioButton
                                    onImageClick={() => { }}
                                    image={props.question.option_type || ''}
                                    onBlur={() => { }}
                                    butttonNumber={'3'}
                                    checked={false}
                                    name={"answer"}
                                    value={"3"}
                                    onChange={() => { }}
                                    text={
                                        props.question.option_type === optionType.LISTENING ? '' :
                                            (props.question?.option3 || '')}
                                />

                            </label>
                            <label className="radio-btn-container flex items-center">
                                <RadioButton
                                    onImageClick={() => { }}
                                    image={props.question.option_type || ''}
                                    onBlur={() => { }}
                                    butttonNumber={'4'}
                                    checked={false}
                                    name={"answer"}
                                    value={"4"}
                                    onChange={() => { }}
                                    text={
                                        props.question.option_type === optionType.LISTENING ? '' :
                                            (props.question?.option4 || '')}
                                />
                            </label>
                        </div>
                    </div>
                </div>



            </div>


            {/* <AnswerList
                        answers={qType === questionType.LISTENING ? listeningAnswer : readingAnswer}
                        flex={1}
                        qType={qType} /> */}
        </div >
    )
}

export default DisplayQuestionPdf
