import { setToasterState } from '../../../../../store/action';
import store from '../../../../../store/store';
import { GetCollectionWhereData } from '../../../../../plugins/firebasefirestore';

const resultUrl = 'Results';

export const getResultsSuccess = (payload: any) => {
  return {
    type: 'GET_RESULTS',
    payload: payload,
  };
};

export const getUserResults = () => (dispatch: any) => {
  const userID = store.getState().authReducer.user.uid;
  if (userID) {
    GetCollectionWhereData(resultUrl, 'user', '==', userID)
      .then((querySnapshot) => {
        const res = querySnapshot.docs.map((doc) => doc.data());
        dispatch(getResultsSuccess(res));
      })
      .catch((err) => {
        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Fetch Error',
            message: 'Error cannot get Exams',
          })
        );
      });
  }
};
