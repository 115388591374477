interface IEditExam {
    closeModal: Function,
    confirmAction: Function,
}

const ExamsRule = (props: IEditExam) => {

    const rules = [
        "Connect to a stable internet so there will be no interuption",
        "If internet disconnects during exam then exam is counted but result will not be saved",
        "You can give a answer or can leave it, It will be marked as not attempted"
    ]
    return (
        <div className="edit-client dialog-content">
            <div className="dialog-content-area flex column">
                <h1>WELCOME TO KOREAN VASA Eps CBT TEST</h1>
                <div>Here are some rules that should be noted before exam.</div>
                <ul>
                    {rules.map(rule =>
                        <li>{rule}</li>
                    )}
                </ul>
            </div>
            <div className="button-area flex justify-end mtyar-lg">
                <button className="btn primary" onClick={() => props.confirmAction()}>Accept Rule And Start Exam</button>
                <button className="btn primary outlined ml-xs" onClick={() => props.closeModal()}>Cancel</button>
            </div>
        </div>
    )
}

export default ExamsRule
